<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="sectorNom"
                label="Sector"
                item-text="name"
                item-value="id"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(sectorNom, 45),
                    rules.requiredTrim(sectorNom)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="alias"
                label="Alias para afiliados y proveedores"
                item-text="name"
                item-value="id"
                dense
                outlined
                :rules="[rules.maxLength(alias, 45)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-switch
                v-model="esSectorInicial"
                label="Es inicial"
                item-text="esSectorInicial"
                item-value="id"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9" class="pb-0">
              <v-select
                v-model="sectorSelected"
                ref="sector-destino"
                :items="sectoresCombo"
                item-text="sectorNom"
                item-value="sectorId"
                label="Sectores destino"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="3" class="pt-4 pb-0">
              <v-btn small @click="addSectorSelected" color="primary"
                >Agregar</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-simple-table dense v-if="sectoresSelected.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sectores destino:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in sectoresSelected" :key="item.id">
                      <td>{{ item.sectorNom }}</td>
                      <v-icon
                        small
                        class="ml-2 pt-2"
                        @click="deleteSectoresSelected(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                outlined
                clearable
                dense
                multiple
                v-model="usersSelected"
                label="Usuarios con permiso"
                item-text="value"
                item-value="id"
                autocomplete="on"
                :items="usuarios"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="usuariosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="usersSelected.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ usersSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <div v-if="usersSelected.length > 0" id="simple-table-container">
                <v-simple-table dense v-if="usersSelected.length > 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Usuarios asignados:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in usersSelected" :key="item.id">
                        <td>{{ item.value }}</td>
                        <v-icon
                          small
                          class="ml-2 pt-2"
                          @click="deleteUserSelected(item)"
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditSector",
  props: ["sectorId"],
  data: () => ({
    deleteIcon: enums.icons.DELETE,
    usersSelected: [],
    esSectorInicial: false,
    isFormValid: false,
    formEditTitle: "Editar sector",
    sectores: [],
    usuarios: [],
    rules: rules,
    sectoresCombo: [],
    sectoresSelected: [],
    sectorSelected: null,
    alias: null,
    sectorNom: null
  }),
  created() {
    if (this.sectorId != null) {
      this.setSector(this.sectorId);
    } else {
      this.newSector();
    }
    this.loadSectores();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllUsuarios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllUsuarios() {
      return (
        this.usersSelected && this.usersSelected.length === this.usuarios.length
      );
    }
  },
  methods: {
    ...mapActions({
      getUsuarios: "devengamientos/getUsuarios",
      getSectores: "configuracionME/getSectores",
      getSectoresById: "configuracionME/getSectoresById",
      postSectores: "configuracionME/postSectores",
      setAlert: "user/setAlert"
    }),
    async loadSectores() {
      const response = await this.getSectores();
      const res1 = await this.getUsuarios();
      this.usuarios = res1;
      this.sectores = response;
      this.loadSectoresCombo();
    },
    async loadSectoresCombo() {
      this.sectoresCombo = [...this.sectores];
      this.sectoresCombo = this.sectores.filter(
        x => x.sectorId !== this.sectorId
      );
    },
    async setSector() {
      const response = await this.getSectoresById(this.sectorId);
      this.sectorNom = response.sectorNom;
      this.esSectorInicial = response.esSectorInicial;
      this.alias = response.alias;
      this.sectoresSelected = response.destinos;
      this.usersSelected = response.usuariosAsignados;
    },
    async newSector() {
      this.formEditTitle = "Nuevo sector";
      this.sectoresCombo = [...this.sectores];
    },
    addSectorSelected() {
      if (this.sectoresSelected.length > 0) {
        let result = this.sectoresSelected.find(
          x => x.sectorId === this.sectorSelected
        );
        if (result) {
          this.setAlert({
            type: "error",
            message: "Ya se encuentra en la lista"
          });
          return;
        }
      }
      const itemToAdd = this.sectoresCombo.find(
        x => x.sectorId === this.sectorSelected
      );
      this.sectoresSelected.push(itemToAdd);
    },
    async saveEdit() {
      const destinos = this.sectoresSelected.map(x => x.sectorId);
      const usuariosAsignados = this.usersSelected.map(x => x.id);
      const data = {
        sectorId: this.sectorId,
        sectorNom: this.sectorNom,
        alias: this.alias,
        esSectorInicial: this.esSectorInicial,
        usuariosIds: usuariosAsignados,
        sectoresDestinosIds: destinos
      };
      const res = await this.postSectores(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    deleteSectoresSelected(item) {
      this.sectoresSelected = this.sectoresSelected.filter(
        x => x.sectorId !== item.sectorId
      );
    },
    deleteUserSelected(item) {
      this.usersSelected = this.usersSelected.filter(x => x.id !== item.id);
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsuarios) {
          this.usersSelected = [];
        } else {
          this.usersSelected = this.usuarios;
        }
      });
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
</style>
